
.brand-wrappered {
    padding-top: 148px;
    padding-right: 180.5px;
    padding-left: 108px;
    padding-bottom: 40px;

}
.brand-wrappered p
{
  
    font-family: 'AvenirNext Medium';
    font-size: 16px;
    line-height: 1.56;
    color: #797278;
    margin:0px;
    padding-top:25px;
}

.formed {
    margin-left: 110px;
    margin-right: 168px;
}
 
.formed .ant-input:placeholder-shown {
  
    font-family: 'AvenirNext Regular';
    font-size: 16px;
    color: #797278;
    line-height: 1.56;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 18px;
}



.sigin-button-reset
{
    width: 100%;
    height: 60px !important;
    margin-top: 35px;
    padding: 19px 180px 14px 178px;
    border-radius: 6px !important;
    background-color: #296ce7 !important;
}
.brand-wrappered h1{
    font-family: 'NunitoSans SemiBold';
    font-size: 36px;
    line-height: 1.36;
    color: #000;
    margin-bottom: 0px;
    margin-top: 38.1px;
}

.sigin-button-resetpassword
{
    width:100%;
    height: 60px !important;
    margin-top: 35px;
    padding: 19px 65px 14px 65px;
    border-radius: 6px !important;
    background-color: #296ce7 !important;
    margin-bottom: 12px !important;
}

.sign-resetpassword{
  
    font-family: 'NunitoSans SemiBold';
    font-size: 20px;
    line-height: 1.35;
    color: #fff;
}




.email-address-input-field-reset-password {
    height: 56px !important;
    border-radius: 6px !important;
    padding-top: 17px !important;
    padding-bottom: 17px !important;
    padding-left: 18px !important;
}

.image-set
{
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 457px;
    padding-top: 97px;
}
.header-here
{
    font-family: 'Inter Bold';
    font-size: 40px;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    padding-top: 29px;
    padding-bottom: 23px;
}
.save-timed
{
        font-family: 'Inter Regular';
        font-size: 20px;
        line-height: 1.5;
        color: #fff;
        padding: 0px 10px 0px 10px;
        text-align: center;
}
.taged {
    margin-top: 63px;
    text-align: start;
    margin-left: 15px;
    margin-bottom: 12px;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
    color: #fff;
}
@media(max-width:1387px)
{
    .image-set {
       
        padding-top: 53px;
        height: auto;
    }
    .brand-wrappered {
        padding-top: 70px;
      
    }
}


@media(max-width:1250px)
{
    .image-set {
        width: 100% !important; 
    }
}
@media(max-width:1024px)
{
    .brand-wrappered {
        padding-right: 10px;
        padding-left: 10px;
     }
     .formed {
        margin-left:10px;
        margin-right:10px;
    }
    .Stay-Organized {
        font-size: 25px;
    }
    .Save-time {
        font-size: 16px;
        }
    
}

@media(max-width:767px)
{

    .sigin-button-reset {
        margin-bottom: 10px !important;
    }

    .image-set {
        width: 78% !important;
    }
    
    .image-set {
      
        padding-top: 26px;
    }
    .brand-wrappered {
        padding-top: 34px;
     }
}