.Price-Plan-button-Class
.ant-select {
    margin-right: 20px;
}
.add-plan-buttons
{
    font-family: 'NunitoSans Bold';
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    margin-left: 6px;
}
.Day-week-buttons-Price .ant-btn {
    height: 40px;
    padding: 11px 9px 10px 11px;
    border-radius: 6px;
    background-color: #296ce7;
}

.Price-Plan-button-Class .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid white !important;
    border-radius: 6px !important;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 38px !important;
    padding: 6px 11px 0px 10px !important;

}

.Price-Plan-button-Class .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
font-size: 14px;
font-family:'NunitoSans SemiBold';
color: black;
}
.Price-Delete-icon
{
    width: 27px;
    height: 20px;
    object-fit: contain;
    margin-right: 4px;
    cursor: pointer;
}
.Plan-add-image
{
    width: 14px;
    height: 14px;
}
.footer-styling
{
    margin: 0px 0px 12px 0px;
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
    color: #000;
}
.Price-Plan-button-Class .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    min-width: 0;
    width: 186px !important;
    height: 40px !important;
    margin: 0px 20px 0px 619px !important;
    padding: 9px 10px 9px 12px !important;
    border-radius: 6px !important;
    background-color: #fff !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: 1px solid #fff !important;
}

.mr-b-23
{
    margin-bottom: 23px;
}


.Price-Plan-Placeholder .ant-input {
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: #707070 !important;
    height: 19px !important;
    }



.Price-Plan-Table-Management .ant-table-column-sorters {
    padding: 16px 20px;
}
.Price-Plan-Table-Management 
.ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 20px;
}
.Price-Plan-Table-Management .ant-table-container table > thead > tr th {
    border-top-left-radius: 2px;
    height: 22px;
    margin: 0 7.2px 16px 20px !important;
    font-family: 'NunitoSans Bold';
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: #333;
}
.Price-Plan-Table-Management td.ant-table-column-sort {
    background: white;
}
.Price-Plan-Table-Management .ant-table-pagination.ant-pagination {
    margin-top :10px;
    margin-bottom: 0px;
}
.Price-Plan-Table-Management .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    background: white;  
}
.Price-Plan-Table-Management .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: white;
}
.Price-Plan-Table-Management .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white;
}
.Price-Plan-Pages {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
  }
 /* VIEW MODAL */

 .modal-assign
 .ant-modal-close-x {
    margin-top: 40px;
    font-size: 23px;
    margin-right: 29px;
    color: #5b5b5b;
 }

 .modal-assign  .ant-modal-body {
    padding-left: 50px;
    padding-top:36px;
    padding-right:48.9px;
 }

 .Assign-Customer
{  
 font-family: 'NunitoSans Bold';
 font-size: 26px;
 font-weight: bold;
 line-height: 1.35;
 margin-bottom: 36px;
 color: #000;
}


.modal-assign .ant-modal-content {

 border-radius: 10px;
}


.Search-Customer {

 font-family: 'NunitoSans SemiBold';
 font-size: 16px;
 font-weight: 600;
 line-height: 1.38;
 color: #313131;
}
.Main-serach .ant-input
{
 width: 699px;
 height: 56px;
 margin: 10px 14px 22px 0;
 padding: 17px 0px 17px 19px;
 border-radius: 4px;
 border: solid 1px #dadada;
 background-color: #fff;
}
.serach-customer .ant-input:placeholder-shown {
 font-family: 'NunitoSans Regular';
 font-size: 16px;
 line-height: 1.38;
 color: #313131;
}

.select-button-for-assign .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {

 height: 56px;
 padding: 13px 16px;

}
.select-button-for-assign .ant-select
{
 width: 205px;
 height: 49px;
 margin: 9px 0 0px 0px;

 background-color: #fff;
}
.select-button-for-assign .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
 padding-right: 4px;
 padding-top: 3px;

 font-family: 'NunitoSans SemiBold';
 font-size: 16px;
 font-weight: 600;
 line-height: 1.38;
 color: #000;

}
.Table-styling
{

    /* height: 422px; */
    margin: 0px 0px 25px 0;
    padding: 2px 0 0;
    border-radius: 4px;
    border: solid 1px #dadada;
    background-color: #fff;
}

.Table-styling .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85) !important;
    font-weight: 500;
    background: white;  
}

.Table-styling .ant-table-thead > tr > th, 
.ant-table-tbody > tr > td, 
.ant-table tfoot > tr > th, 
.ant-table tfoot > tr > td {
 padding-top:25px !important;
 padding-bottom: 23px !important;
}
.Table-styling
.ant-table-tbody > tr.ant-table-row:hover > td {
 background: white;
}
.Table-styling 
.ant-table-thead > tr > th {
 background: white;
 border-bottom: 1px solid #e9e9e9;
}

.Table-styling
.ant-table-tbody > tr.ant-table-row-selected > td {
 background: #f5f9ff !important;

}
.Table-styling
.ant-table-thead > tr > th {

   font-family: 'NunitoSans SemiBold';
   font-size: 18px;
   font-weight: 600;
   line-height: 1.33;
  
   color: #000 !important;
}
.Table-styling .ant-table-pagination.ant-pagination {
 margin: 16px 0;
 display: none;
}
.Table-styling  .ant-checkbox-checked .ant-checkbox-inner  {
 background-color: #296ce6;
 border-color: #296ce6;
}

/* .ant-checkbox-checked .ant-checkbox-inner::after {
 position: absolute;
 display: table;
 border: 2px solid #fff;
 border-top: 0;
 border-left: 0;
 -webkit-transform: rotate(
45deg
) scale(1) translate(-50%, -50%);
 transform: rotate(
45deg
) scale(1) translate(-56%, -50%);
 opacity: 1;
 -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
 transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
 content: ' ';
} */

.Table-styling  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
 /* top: 79%;
 left: 50%;
 width: 8px;
 height: 8px;
 background-color: #1890ff;
 border: 0;
 -webkit-transform: translate(-50%, -50%) scale(1);
 transform: translate(-50%, -50%) scale(1);
 opacity: 1;
 content: ' '; */
 position: absolute;
 display: table;
 border: 2px solid #fff;
 border-top: 0;
 border-left: 0;
 -webkit-transform: rotate(
45deg
) scale(1) translate(-50%, -50%);
 transform: rotate(
45deg
) scale(1) translate(-56%, -50%);
 opacity: 1;
 -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
 transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
 content: ' ';
 top: 52%;
 left: 24%;
 width: 6px;
 height: 9px;
 background-color: #296ce6;
}

.Table-styling .ant-checkbox-indeterminate .ant-checkbox-inner {
 background-color: #296ce6;
 border-color: #296ce6;
}
.modal-assign .ant-modal-footer {
display: none;    
}

.Modal-Button  .Cancel-button .Button-Cancel{
   
    margin-left: 614px;
    margin-right: 20px;
    width: 142px;
    height: 60px;
    padding: 17px 40px 16px;
    border-radius: 6px;
    background-color: #f3f5fa;
    cursor: pointer;

}
.Modal-Button .Cancel {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #5a5a5a;
  }

  .Modal-Button  .Create-button .Button-Create{
    cursor: pointer;
    width: 140px;
    height: 60px;
   
    padding: 17px 50px 16px 51px;
    border-radius: 6px;
    background-color: #296ce6;
}
.Modal-Button .Create {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
  }


  
/* .Modal-form-serach .ant-input
{
 width: 699px;
 height: 56px;
 margin: 10px 14px 0px 0;
 padding: 17px 0px 17px 19px;
 border-radius: 4px;
 border: solid 1px #dadada;
 background-color: #fff;
} */
