.email-address-input-field .ant-input-suffix {
    font-size: 18px;
}

.mr-top-35 {
    margin-top: 35px;
}

.password-input-field-forgot-password .ant-input-suffix {
    font-size: 18px;
}

.form-page {
    margin-left: 107px;
    margin-right: 201px;
}

.sigin-button {
    margin-top: 48px !important;
}

.image-background-color {
    background-color: #0055c1;
    height: 100vh;
    position: fixed;
    max-width: 100%;
    width: 49%;
}

.email-address {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
    margin-bottom: 10px;
    display: inline-block;
}

.brand-wrapper-forgotpassword p {

    font-family: 'AvenirNext Medium';
    font-size: 16px;
    line-height: 1.56;
    margin: 0px;
    color: #797278;
}

.brand-wrapper-forgotpassword {
    padding-top: 148px;
    padding-right: 247.5px;
    padding-left: 110px;
    padding-bottom: 40px;
}

.brand-wrapper-forgotpassword h1 {

    font-family: 'NunitoSans SemiBold';
    font-size: 36px;
    line-height: 1.36;
    color: #000;
    margin-bottom: 25px;
    margin-top: 38.1px;
}

.sigin-button-forgotpassword {
    width: 100%;
    height: 60px !important;
    margin-top: 48px;
    padding: 19px 65px 14px 65px;
    border-radius: 6px !important;
    margin-bottom: 12px !important;
    background-color: #296ce7 !important;
}

.sign-forgotpassword {

    font-family: 'NunitoSans SemiBold';
    font-size: 20px;
    line-height: 1.35;
    color: #fff;
}

.err-message-Confirmpassword {
    color: red;
    margin-bottom: 0px;
}


.err-message-Forgotpassword {
    color: red;
    margin-bottom: 7px;
}

.password-input-field-forgot-password {
    height: 56px !important;
    margin-bottom: 9px !important;
    border-radius: 6px !important;
}

.email-address-input-field {
    height: 56px !important;
    margin-bottom: 9px !important;
    border-radius: 6px !important;
}

.brand-wrapper {
    padding-top: 148px;
    padding-right: 239.5px;
    padding-left: 110px;
    padding-bottom: 40px;
}

.brand-wrapper p {

    font-family: 'AvenirNext Medium';
    font-size: 16px;
    line-height: 1.56;
    color: #797278;
    margin: 0px;
    padding-top: 25px;
}

@media(max-width:1383px) {
    .brand-wrapper-login {
        padding-top: 42px;
    }
}

@media(max-width:1387px) {
   
    .brand-wrapper-forgotpassword {
        padding-top: 70px;

    }
}

@media(max-width:1250px) {
    .header-here {
        font-size: 26px;
    }

    .save-timed {
        font-size: 15px;
    }

    .image-scorll {
        width: 100% !important;
    }
}

@media(max-width:1024px) {
    .brand-wrapper-forgotpassword {
        padding-right: 10px;
        padding-left: 10px;
    }

    .form-page {
        margin-left: 10px;
        margin-right: 10px;
    }

    .Stay-Organized {
        font-size: 25px;
    }

    .Save-time {
        font-size: 16px;
    }

}

@media(max-width:767px) {

    .sigin-button-forgotpassword {
        margin-bottom: 10px !important;
    }

    .image-background-color {

        width: 100% !important;
        position: inherit !important;
        height: auto !important;
    }


    .image-scorll {
        width: 78% !important;
    }

    .image-scorll {

        padding-top: 26px;
    }

    .brand-wrapper-forgotpassword {
        padding-top: 34px;
    }
}