.Super-Create-button .edit-Buttons {
  border-radius: 6px;
  background-color: #296ce6;
  /* width: 132px; */
  height: 60px;
  padding: 17px 50px 16px;
  cursor: pointer;
}

.mr-left-235 {
  margin-left: 235px;
  margin-right: 235px;
  padding-top: 0px;
}

.err-message-email {
  color: red;
  margin-bottom: 39px;
}

.mr-b-20 {
  margin-bottom: 20px;
}

.Home-Add-Plan-Super {
  opacity: 0.5;
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  color: #000;
  margin-left: 17px;
  margin-right: 2px;
}

.chevron-right-1-Plan-Super {
  width: 4px;
  height: 8px;
  opacity: 0.5;
  margin-right: 6px;
}

.Price-Plan-Date-Super {
  opacity: 0.5;
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  color: #000;
  margin-right: 5px;
}

.Add-Plan-create-Super {
  font-family: 'NunitoSans SemiBold';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: #333;
}

.Border-Div-Add-plan-Super {
  width: 100%;
  height: auto;
  margin-bottom: 14px;
  padding-bottom: 71px;
  margin: auto;
  background-color: #fff;

}

.Plan-Add-Main-Super {
  margin-left: 235px;
  margin-right: 235px;
  padding-top: 44px;
}


.Super-Price-Inout-Filed .ant-input {
  margin-top: 12px !important;
  margin-bottom: 9px !important;
  height: 56px !important;
  padding: 17px 0px 17px 19px !important;
  border-radius: 4px !important;
  border: solid 1px #dadada !important;
  background-color: #fff;

}

.Super-Price-Inout-Filed .ant-input:placeholder-shown {
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  color: #707070 !important;
}

.Password-text-plan .ant-input {
  height: 56px !important;
}

.Password-text-plan .ant-input:placeholder-shown {
  font-family: 'NunitoSans Regular';
  font-size: 16px;
  line-height: 1.38;
  color: #707070 !important;
}

.Password-text-plan .ant-input-suffix {
  font-size: 18px;
}

.hr-styling {
  margin-top: 18px;
  margin-bottom: 20px;
  height: 1px;
  border-radius: 6px;
  opacity: 0.5;
  color: #dadada;
}

.Super-Create {
  width: 60px;
  height: 27px;
  font-family: 'NunitoSans Bold';
  font-size: 20px;
  line-height: 1.35;
  color: #fff;
}

.Super-Cancel-button .Button-Super-Cancel {
  /* margin-left: 711px; */
  margin-right: 20px;
  /* width: 140px; */
  height: 60px;
  padding: 17px 40px 16px;
  border-radius: 6px;
  background-color: #f3f5fa;
  cursor: pointer;
}

.Super-Cancel {
  width: 60px;
  height: 27px;
  font-family: 'NunitoSans Regular';
  font-size: 20px;
  line-height: 1.35;
  color: #5a5a5a;
}


.footer-styling {
  margin: 0px 0px 12px 0px;
  opacity: 0.5;
  font-family: 'NunitoSans Regular';
  font-size: 12px;
  line-height: 1.33;
  color: #000;
}

.Super-Admin-plan-pages {
  font-family: 'NunitoSans Bold';
  font-size: 26px;
  font-weight: bold;
  line-height: 1.35;
  color: #000;
}

.mr-bottom-39 {
  margin-top: 39px;
  margin-bottom: 12px;
  display: inline-block;
}

.mr-18 {
  margin-bottom: 18px;
}

.passwordteststyle {
  margin-bottom: 12px;
  display: inline-block;
}

.Super-Create-button .Admin-Super-Button {
  border-radius: 6px;
  background-color: #296ce6;
  width: 140px;
  height: 60px;
  padding: 17px 40px 16px;
  cursor: pointer;

}

@media(max-width:991px) {
  .Plan-Add-Main-Super {
    padding: 10px 10px 10px 10px;
    margin: auto;
  }
  .Client-Add-plan-Tag {
    padding: 10px 10px 10px 10px;
    margin: auto;
}

  .mr-left-235 {
    padding: 10px 10px 10px 10px;
    margin: auto;
  }
}