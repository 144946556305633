/* THIS ALL FOR  AVENIR STYLE */

@font-face {
  font-family: 'AAvenirNext Bold';
  src:url('../fonts/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext Demi';
  src:url('../fonts/AvenirNextLTPro-Demi.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext Heavy';
  src:url('../fonts/AvenirNextLTPro-Heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext Medium';
  src:url('../fonts/AvenirNextLTPro-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext Regular';
  src:url('../fonts/AvenirNextLTPro-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/* THIS ALL FOR  INTER FONT STYLE */

@font-face {
    font-family: 'Inter Black';
    src:url('../fonts/Inter-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Bold';
    src:url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter ExtraBold';
    src:url('../fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter ExtraLight';
    src:url('../fonts/Inter-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Light';
    src:url('../fonts/Inter-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Medium';
    src:url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Regular';
    src:url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Semibold';
    src:url('../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Inter Thin';
    src:url('../fonts/Inter-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
 
  /* THIS ALL FOR NATUS SANCS FONT  */

  
@font-face {
  font-family: 'NunitoSans Black';
  src:url('../fonts/NunitoSans-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans BlackItalic';
  src:url('../fonts/NunitoSans-BlackItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans Bold';
  src:url('../fonts/NunitoSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans ExtraBold';
  src:url('../fonts/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans ExtraBoldItalic';
  src:url('../fonts/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans ExtraLight';
  src:url('../fonts/NunitoSans-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans ExtraLightItalic';
  src:url('../fonts/NunitoSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans Italic';
  src:url('../fonts/NunitoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans Light';
  src:url('../fonts/NunitoSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans LightItalic';
  src:url('../fonts/NunitoSans-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans Regular';
  src:url('../fonts/NunitoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans SemiBold';
  src:url('../fonts/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans SemiBoldItalic';
  src:url('../fonts/NunitoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

  /* THIS ALL FOR HELVETICA NEUE  */

  @font-face {
    font-family: 'HelveticaNeue';
    src:url('../fonts/HelveticaNeue.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueBold';
    src:url('../fonts/helvetica-neue-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueLight';
    src:url('../fonts/helvetica-neue-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueMedium';
    src:url('../fonts/helvetica-neue-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueRegular';
    src:url('../fonts/helvetica-neue-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }