.mr-b-20
{
margin-bottom: 20px;
}

.View-details-Price-tex
{
margin: 46px 205px 12px 0;
font-family: 'NunitoSans SemiBold';
font-size: 16px;
line-height: 1.38;
color: #525252;

}

.View-detaiks-Price-Plans-home{
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #000;
    margin-left: 17px;
    margin-right: 2px;
}
.chevron-right-1-View-details-plans {
    width: 4px;
    height: 8px;
    opacity: 0.5;
    margin-right: 6px;
   
  }
.View-detaikls-price-plan
{
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #000;
}
.main-checkboxed
{
margin-bottom: 22px;
}
.checkbox-text-value
{   
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #000;
}
.tickmarker
{
    width: 16px;
    height: 16px;
    object-fit: contain; 
    margin-right: 13px;
}
.Features-class
{
    margin: 0 0px 22px 0;
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #525252;
}
.VIew-DetailsPricepLan-texted
{ 
    margin: 46px 0px 12px 0;
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #525252;
}
.price
{  
    margin: 12px 152px 46px 0;
    font-family: 'NunitoSans Bold';
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #000;
}
.Price-Loreum-text
{ 
    margin: 13px 0 0;
    font-family: 'NunitoSans Regular';
    font-size: 16px;
    line-height: 1.38;
    color: #333;
}
.Icon-Vieww-details
{
    width: 19.8px;
    height: 19.8px;
    object-fit: contain;
}
.upper-class-name-view
{
    width: 40px;
    height: 40px;
    padding: 10.5px 9.7px 9.7px 10.5px;
    border-radius: 6px;
    background-color: #f6f6f6;
    display: inline-block;
}
.VIew-details-SecondPlans
{
    width: 40px;
    height: 40px;
    margin: 0 0px 0px 12px;
    padding: 10.5px 9.7px 9.7px 10.5px;
    border-radius: 6px;
    background-color: #f6f6f6;
    display: inline-block;
}
.Creat-Plan-View-details
{
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #333;
}
.View-Details-Plan-Border
{
    width: 100%;
    height: 637px;
    padding: 41px 35px 47px;
    background-color: #fff
}

.Vieew-Details-Price-plan-Days .ant-btn {
    height: 40px;
    padding: 11px 16px 10px 17px;
    border-radius: 6px;
    background-color: #296ce7;
    margin-left: 20px;
}
.footer-styling-View-Details-Page
{
    margin: 40px 0px 19px 0px;
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
    color: #000;
}
.View-details-plan-view-basic {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
  }
  .Plan-View-Details-Pages {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
  }