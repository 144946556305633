


.ant-table-measure-row
{
display: none;
}

.pb-right-10
{
padding-right: 10px;
}

.Regino
{
text-transform: uppercase;
}
.settingimage
{
    height: 100%;
    width: 100%;
    /* object-fit: cover; */
}
.cursor
{
    cursor: pointer;
}
.Client-Management-buttons .ant-btn {
    height: 40px;
    padding: 11px 9px 10px 11px;
    border-radius: 6px;
    background-color: #296ce7;
    margin-left: 17px;
}


.Client-Managemenet-Serach .ant-select {
    margin-right: 0px !important;
}

.Client-Managemenet-Serach .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid white !important;
    border-radius: 6px !important;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 38px !important;
    padding: 6px 11px 0px 10px !important;

}

.Client-Managemenet-Serach .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
font-size: 14px;
font-family:'NunitoSans SemiBold';
color: black;

}

.ClientManagement-footer-styling
{
    margin:  53px 0px 12px 0px;
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
   
    color: #000;
}

.Client-Managemenet-Serach .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    min-width: 0;
    width: 217px !important;
    height: 40px !important;
    margin: 0px 0px 0px 576px !important;
    padding: 9px 10px 9px 12px !important;
    border-radius: 6px !important;
    background-color: #fff !important;
    display: inline-flex;
    border: 1px solid #fff !important;
}

.mr-b-23
{
    margin-bottom: 23px;
}

/* .Client-serach-placeholder .serach-input .ant-input:placeholder-shown {
    font-family: 'NunitoSans Regular';
    font-size: 22px !important;
    line-height: 1.38 !important;
    color: #707070 !important;
    height: 22px !important;
} */

.Client-Managemenet-Serach .ant-input {
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: #707070 !important;
    height: 19px !important;
    }



    
.parentTable .ant-table-column-sorters {
    padding: 16px 20px;
}
.parentTable 
.ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
/*    
    padding: 16px 20px;
    */
    padding: 16px 5px;
}

.parentTable .ant-table-container table > thead > tr th {
    border-top-left-radius: 2px;
    height: 22px;
    margin: 0 7.2px 16px 20px !important;
    font-family: 'NunitoSans Bold';
    font-size: 16px; 
    line-height: 1.38;
   
    color:black;
}
.parentTable .ant-table-thead > tr > th {
   
    background:white;
  
}

.nestedTable  .ant-table-container table > thead > tr th {

    font-family: 'NunitoSans Regular';
    font-size: 14px;
    color: #333;
}


.nestedTable  .ant-table-tbody > tr >td{
    background: #f3f5fa !important;
    border: none;
    margin: 0 7.2px 16px 20px !important;
    font-family: 'NunitoSans Regular';
    font-size: 14px;
    line-height: 1.38;  
}





.parentTable td.ant-table-column-sort {
    background: white;
}


/* .parentTable .ant-table-pagination.ant-pagination {
    margin-top :10px;
    margin-bottom: 0px;
} */

.nestedTable .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: white !important;
}
/* .nestedTable .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white !important;
} */
.CLient-Mangement-Page {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
  }
  .arrow-delete-icon-client
  {
    width: 16px;
    height: 12px;
    margin-top: 6px;
  }
  .delete-icon-client
  {
    width: 18px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
  }
  .ant-switch-checked {
    background-color: #296ce7 !important;
}
.styling-tables
{
        font-family: 'NunitoSans Regular';
        font-size: 14px;
        line-height: 1.36;
        /* color: #333; */
}
.parentTable tr.ant-table-expanded-row > td,.parentTable tr.ant-table-expanded-row:hover > td{
    background: #f3f5fa !important;
}

.parentTable  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white !important;
} 
.ant-table-thead th.ant-table-column-has-sorters:hover {
    background: white !important;
}



/* Kruti Code */

/* .nestedTable tr.ant-table-expanded-row > td{
    background-color: #f3f5fa !important;
}
.nestedTable .ant-table-thead > tr >th{
    background: #f3f5fa !important;
    border: none;
} */


/* .nestedTable .ant-table-expanded-row{
    width: 20% !important;
}
.nestedTable .ant-spin-nested-loading{
    background: #f3f5fa !important;
    
}
.nestedTable .ant-table-expanded-row-level-1{
    background: #f3f5fa !important;
    
} */

/* .parentTable tr.ant-table-expanded-row > td,.parentTable tr.ant-table-expanded-row:hover > td{
    background: #f3f5fa !important;

}
.parentTable .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table{
    margin: -16px -16px -16px 0px !important;
} */

/* .nestedTable .ant-table-thead > tr > th, 
.nestedTable .ant-table-tbody > tr > td,
 .nestedTable .ant-table tfoot > tr > th,
.nestedTable  .ant-table tfoot > tr > td{
    padding-top: 2px !important;
    padding-bottom: 7px !important;
    padding-left: 0px !important;
} */
/* .paddingnull{
    margin-left: 10px !important;
}
.ant-table-row-expand-icon-cell{
    padding: 0px !important;
} */
/* .parentTable .ant-table-thead > tr > th[colspan]:not([colspan='1']){
    text-align: left;
}
.parentTable  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white !important;
} 
.ant-table-thead th.ant-table-column-has-sorters:hover {
    background: white !important;
} */
/* .DataCourse
.nestedTable  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white !important;
}  */

.Confirm-Delete .ant-modal-content {
    border-radius: 10px;
   }
   .Confirm-Delete .ant-modal-footer {
   display: none;    
   }
   .Confirm-Delete .Cancel-button .Button-Cancel{
    width: 106px;
    cursor: pointer;
    height: 40px;
    margin: 0 18px 13px 243px;
    padding: 7px 35px 6px 40px;
    border-radius: 3px;
    background-color: #f3f5fa;
}
.Confirm-Delete .Cancel {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #5a5a5a;
  }
  .Confirm-Delete  .Create-button .Button-Create{
    cursor: pointer;
    width: 100px;
    height: 40px;
    margin: 0px 0px 0;
    
    padding: 7px 30px 8px 39px;

    border-radius: 3px;
    background-color: #296ce6;
}
.Confirm-Delete .Create {
    width: 60px;
    height: 27px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
    line-height: 1.35;
    color: #ffffff;
  }
  .Confirm-Delete-fucntio
  {   
    height: 80px;
    margin: 0 0 29px;
    padding: 25px 0px 23px 28px;
    background-color: #f3f5fa;
  }
  .confirm-delete-text
  {
    font-family: 'NunitoSans Bold';
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
  }
.Confirm-Delete .ant-modal-close-x {
    display: none !important;
}
.Confirm-Delete .ant-modal-body {
    padding: 3px !important;
}
.Confirm-Delete .are-you-select
{
    margin: 0px 51px 40px 31px;
    font-family: 'NunitoSans Regular';
    font-size: 20px;
     line-height: 1.35;
    color: #333;
}
@media(max-width:1351px) {
    .Client-Managemenet-Serach .ant-input-affix-wrapper
    {
        width: 155px !important;
        margin: 0px 0px 7px 0px !important;
    }
}
@media(max-width:991px) {
    .Client-Managemenet-Serach .ant-input-affix-wrapper {
        width: 100% !important;
        margin-bottom:10px !important;
    }
    .Client-Management-buttons .ant-btn {
        width: 100%;
        margin-left: 0px;
    }
    .Confirm-Delete .Cancel-button .Button-Cancel {
       
        margin: 0 18px 13px 23px;
           }
}