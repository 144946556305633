

.ant-table-measure-row
{
display: none;
}



.mr-right-17
{
    margin-right: 17px;
}
.Super-Admin-Plan-button
.ant-select {
    margin-right: 20px;
}

.SuperAdmin-Buttons-Admin
{
    font-family: 'NunitoSans Bold';
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    margin-left: 6px;
}
.SuperAdmin-Button-Prices .ant-btn {
    height: 40px;
    padding: 11px 9px 10px 11px;
    border-radius: 6px;
    background-color: #296ce7;
}

.Super-Admin-Plan-button .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid white !important;
    border-radius: 6px !important;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    height: 38px !important;
    padding: 6px 11px 0px 10px !important;
}

.Super-Admin-Plan-button .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
font-size: 14px;
font-family:'NunitoSans SemiBold';
color: black;
}
.SuperAdmin-Add-Image
{
    width: 14px;
    height: 14px;
}
.SuperAdmin-Footer-Styling
{
    margin: 53px 0px 12px 0px;
    opacity: 0.5;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
    color: #000;
}
.Super-Admin-Plan-button .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    min-width: 0;
    width: 186px !important;
    height: 40px !important;
    margin: 0px 20px 0px 619px !important;
    padding: 9px 10px 9px 12px !important;
    border-radius: 6px !important;
    background-color: #fff !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: 1px solid #fff !important;
}
.mr-b-23
{
    margin-bottom: 23px;
}
.SuperAdmin-Planes-Placeholder .ant-input {
    font-size: 16px;
    font-family: 'NunitoSans Regular';
    color: #707070 !important;
    height: 19px !important;
    }
.SuperAdmin-Table-mamagement .ant-table-column-sorters {
    padding: 16px 20px;
}
.SuperAdmin-Table-mamagement 
.ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 16px 20px;
}
.SuperAdmin-Table-mamagement .ant-table-container table > thead > tr th {
    border-top-left-radius: 2px;
    height: 22px;
    margin: 0 7.2px 16px 20px !important;
    font-family: 'NunitoSans Bold';
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: #333;
}
.SuperAdmin-Table-mamagement td.ant-table-column-sort {
    background: white;
}
.SuperAdmin-Table-mamagement .ant-table-pagination.ant-pagination {
    margin-top :10px;
    margin-bottom: 0px;
}
.SuperAdmin-Table-mamagement .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    background: white;  
}
.SuperAdmin-Table-mamagement .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: white;
}
.SuperAdmin-Table-mamagement .ant-table-tbody > tr.ant-table-row:hover > td {
    background: white;
}
.SuperAdmin-Planes-Pages {
    font-family: 'NunitoSans Bold';
    font-size: 26px;
    font-weight: bold;
    line-height: 1.35;
    color: #000;
  }
 @media(max-width:1351px) {
    .Super-Admin-Plan-button .ant-input-affix-wrapper {
        width: 155px !important;
        margin: 0px 7px 0px 0px !important;
    }
}

@media(max-width:991px) {
    .Super-Admin-Plan-button .ant-input-affix-wrapper {
        width: 100% !important;
        margin-bottom:10px !important;
    }
    .SuperAdmin-Button-Prices .ant-btn {
        width: 100%;
        /* margin-left: 10px; */
    }
}