a:hover {
    text-decoration: none !important;
}

.ant-picker-clear {
    margin-right: 20px;
}


.mr-29 {
    margin-right: 34px;
}

.breadcumbs-design {
    display: block;
    margin-top: 7px;
}

.display-value {
    display: flex;
}

.err-message-email-password {
    color: red;
    margin-bottom: 10px;
}

.set-loader {
    position: fixed;
    z-index: 99999;
    top: 57%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    right: 0;
    stroke: #66b6e0;
    fill: #66b6e0;
}

.dont-have-account {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
    margin-bottom: 22px;
}

.login-section-wrappers {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    background-color: #fff;
}

.password-input-field-loginpaged .ant-input-suffix {
    font-size: 18px;
}

.brand-wrapper-login {
    padding-top: 148px;
    padding-right: 276.5px;
    padding-left: 110px;
    padding-bottom: 42px;
}

.email-address-input-field-loginpage {
    height: 56px !important;
    margin-bottom: 6px !important;
    border-radius: 6px !important;
}

.sigin-button-loginpage {
    width: 100%;
    height: 60px !important;
    margin-top: 50px;
    /* padding: 19px 180px 14px 178px; */
    border-radius: 6px !important;
    background-color: #296ce7 !important;
}

.sign-loginpage {

    font-family: 'NunitoSans SemiBold';
    font-size: 20px;
    line-height: 1.35;
    color: #fff;
}

.mrt-3 {
    margin-right: -3px !important;
}

.forgot-password-text-login a:active {
    color: #333 !important;
    cursor: pointer;
}

.forgot-password-text-login a {
    color: #333 !important;
    cursor: pointer;
}


.forgot-password-text-login {

    font-family: 'NunitoSans Regular';
    font-size: 14px;
    line-height: 1.36;
    color: #333;
}

.password-input-field-loginpage {
    height: 56px !important;
    margin-bottom: 12px !important;

    border-radius: 6px !important;
}

.password-input-field-loginpaged {
    height: 56px !important;
    margin-bottom: 12px !important;

    border-radius: 6px !important;
}

.brand-wrapper-login h1 {

    font-family: 'NunitoSans SemiBold';
    font-size: 36px;
    line-height: 1.36;
    color: #000;
    margin-bottom: 0px;
}

.form-page-login {
    margin-left: 110px;
    margin-right: 110px;
}

.email-address-login {
    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
    margin-bottom: 10px;
    display: inline-block;
}

.password-loginpage {

    font-family: 'NunitoSans SemiBold';
    font-size: 16px;
    line-height: 1.38;
    color: #313131;
    margin-bottom: 10px;
    display: inline-block;
}

.logo-loginpage {
    padding-bottom: 38.1px;
}

.logo-page-forresetpassword {
    padding-bottom: 10px;
}

.login-carousel .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
    display: none;
}

.image-background-color-loginpage {
    background-color: #0055c1;
    height: 100vh;
    /* position: fixed; */
    max-width: 100%;
    width: 100%;
}

.image-scorll-loginpage {
    width: 457px !important;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-top: 123px;

}

.Stay-Organized {
    font-family: 'Inter Bold';
    font-size: 40px;
    line-height: 1.23;
    color: #fff;
    margin-bottom: 23px;
    margin-top: 29px;
}

.Save-time {
    font-family: 'Inter Regular';
    font-size: 20px;
    padding: 0px 10px 0px 10px;
    line-height: 1.5;
    color: #fff;
}

.login-carousel .carousel .control-dots {
    margin: 12px 0 !important;
}

.tag-login {
    margin-top: 80px;
    text-align: start;
    margin-left: 15px;
    margin-bottom: 12px;
    font-family: 'NunitoSans Regular';
    font-size: 12px;
    line-height: 1.33;
    color: #fff;
}

.login-carousel .carousel .control-dots .dot.selected,
.carousel .control-dots .dot:active {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px 0 0 !important;
    width: 24px !important;
    height: 8px !important;
    border-radius: 4px !important;
    background: white !important;
}

.login-carousel .carousel .control-dots .dot {
    cursor: pointer;
    display: inline-block;
    width: 14px !important;
    height: 8px !important;
    margin: 0 5px 0 0 !important;
    opacity: 0.5;
    border-radius: 4px !important;
}

.login-carousel .carousel.carousel-slider .control-arrow {
    display: none;

}

.login-carousel .carousel .carousel-status {
    display: none;

}

@media(max-width:1383px) {
    .image-scorll-loginpage {

        padding-top: 49px;
        width: 100% !important;
    }

    .brand-wrapper-login {
        padding-top: 42px;
    }
}

@media(max-width:1024px) {
    .brand-wrapper-login {
        padding-right: 10px;
        padding-left: 10px;
    }

    .form-page-login {
        margin-left: 10px;
        margin-right: 10px;
    }

    .Stay-Organized {
        font-size: 25px;
    }

    .Save-time {
        font-size: 16px;
    }

}

@media(max-width:767px) {
    .logo-loginpage {
        margin: auto;
        height: 55px;
        display: block;
    }

    .logo-page-forresetpassword {
        margin: auto;
        display: block;
    }

    .image-background-color-loginpage {
        height: 60vh !important;
    }

    .Stay-Organized {
        font-size: 17px !important;
        margin-bottom: 9px;
        margin-top: 21px;
    }

    .Save-time {
        font-size: 14px !important;
    }

    .tag-login {
        margin-top: 30px;
        text-align: center;
        line-height: normal;
        padding-bottom: 28px;
    }

    .image-scorll-loginpage {}

    .sigin-button-loginpage {
        margin-bottom: 10px !important;
    }


    .image-scorll-loginpage {
        width: 78% !important;
        height: 217px !important;
        /* padding-top: 97px; */
    }

    .brand-wrapper-login {
        padding-top: 34px;
    }
}

@media(max-width:768px) {
    .inactive-admin {
        margin-top: -24px !important;
    }

    .inactive-users {
        margin-top: -24px !important;
    }

    .inactive-client {
        margin-top: -24px !important;
    }

    .google-icon-android {
        margin-top: -16px;
    }
}

@media(max-width:1047px) {
    .clients-card-responive {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
    }
}


@media (max-width: 991px) {
    .display-value {
        display: block;
    }

}

@media(max-width:576px) {
    .ant-pagination-options {
        display: initial !important;
    }
}

@media (max-width:425px) {
    .image-background-color-loginpage {
        height: 56vh !important;
    }

    .image-scorll-loginpage {
        height: 182px !important;
    }
}

@media (max-width:414px) {
    .Home-Add-Plan-Super {
        font-size: 12px !important;
    }

    .Price-Plan-Date-Super {
        font-size: 12px !important;
    }

    .Add-Plan-create-Super {
        font-size: 12px !important;
    }

    .Super-Admin-plan-pages {
        font-size: 16px !important;
    }

    .breadcumbs-design {
        margin-top: 0px !important;
    }

    .Home-Add-Plan-Client {
        font-size: 12px !important;
    }

    .Price-Plan-Date-Client {

        font-size: 12px !important;
    }

    .Client-Add-Plan-pages {
        font-size: 12px !important;
        line-height: 2.00 !important;
    }

    .Add-Plan-Client-Add-Create {
        font-size: 11px !important;
    }
}